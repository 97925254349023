
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Lga from "@/components/reusable/Lga.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "states-component",
  components: { Field, ErrorMessage, Lga },
  props: {
    widgetClasses: String,
    selectedOption: String,
    lgaOption: String,
    title: String,
    stateName: String,
    displayValue: Boolean,
  },
  data() {
    return {
      states: [],
      stateLgas: [],
      stateValue: "",
    };
  },
  created() {
    this.getStates();
    this.getLgas();
  },
  methods: {
    getStates() {
      //Fetch list of States in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.STATES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.states = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getStateLgas(event) {
      const value = event.target.value;
      this.stateValue = value;

      //Fetch list of Lgas of the selected Nigerian state
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.STATE_LGAS_ROUTE, value)
          .then(({ data }) => {
            //Assign data to form fields
            this.stateLgas = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getLgas() {
      //Fetch list of States in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.LGAS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.stateLgas = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
  },
  computed: {
    mainSelectedOption() {
      return this.selectedOption;
    },
  },
});
