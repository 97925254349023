
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";

export default defineComponent({
  name: "lgas-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    stateLgas: Object,
    lgaOption: String,
    lgaName: String,
    displayValue: Boolean,
  },
  data() {
    return {
      lgaValue: "",
    };
  },
  methods: {
    capitalize(value) {
      return variables.capitalize(value);
    },
    getLgaValue(event) {
      // this.lgaValue = event.target.value;
      this.lgaValue =
        event.target.options[event.target.selectedIndex].dataset.reference;
    },
  },
  computed: {
    mainSelectedOption() {
      return this.lgaOption;
    },
  },
});
