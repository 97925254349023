
import { defineComponent } from "vue";
import Generator from "@/components/reusable/GeneratorAddition.vue";
import EditGenerator from "@/components/reusable/GeneratorAdditionEdit.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import $ from "jquery";

export default defineComponent({
  name: "own-building-addition-step-3",
  components: {
    Generator,
    EditGenerator
  },
  props: {
    generatorRate: Number,
    generatorRatePublicId: String,
    policyId: String,
    policySummary: Object,
    cartItemPublicId: String,
    grandPremium: Number
  },
  data() {
    return {
      totalPremium: 0.0 as number,
      imageURL: ""
    };
  },
  created() {
    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    },
    deleteImage(index, fileName) {
      const route = `${variables.CART_ROUTE}/${this.cartItemPublicId}/${fileName}`;
      Swal.fire({
        title: "Are you sure you want to delete this image?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007ae1",
        cancelButtonColor: "#ff3e61",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.value == true) {
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.delete(route)
              .then(({ data }) => {
                //Assign data to form fields
                variables.toastAlert(data.data, "success");
                $(".remove-image-" + index).remove();
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      });
    }
  },
  computed: {
    mainPolicySummary() {
      return this.policySummary;
    }
  }
});
