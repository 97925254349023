
import { defineComponent } from "vue";
import UploadImages from "vue-upload-drop-images";

export default defineComponent({
  name: "images-uploader-component",
  components: { UploadImages },
  props: {
    name: String,
    model: String,
  },
  // methods: {
  //   handleImages(files) {
  //     console.log(files.srcElement.files);
  //   }
  // }
});
