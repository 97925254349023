
import { defineComponent } from "vue";

export default defineComponent({
  name: "go-back-button-",
  components: {},
  props: {
    widgetClasses: String,
    title: String,
    displayIcon: Boolean
  },
  methods: {
    goBack() {
      return this.$router.go(-1);
    }
  }
});
