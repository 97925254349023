import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a7ac7a7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "svg-icon svg-icon-white svg-icon-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
    class: _normalizeClass(_ctx.widgetClasses)
  }, [
    (_ctx.displayIcon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Arrow-left.svg" })
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.title ? _ctx.title : "Back"), 1)
  ], 2))
}