
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Generators from "@/views/reusable/buy-insurance/house-insurance/generators.json";

export default defineComponent({
  name: "generator-addition-edit-component",
  components: { Field, ErrorMessage },
  props: {
    valueClasses: String,
    brandClasses: String,
    photoClasses: String,
    photoReceiptClasses: String,
    rate: Number,
    generatorRatePublicId: String,
    policyId: String,
    policySummary: Object,
    grandPremium: Number,
    premiumCalculationRoute: String,
  },
  data() {
    return {
      generatorLists: Generators,
      generators: [
        {
          generatorValue: "",
          generatorBrand: "",
          generatorPhoto: "",
          generatorReceiptPhoto: "",
        },
      ],
      totalPremium: 0.0 as number,
    };
  },
  created() {
    setTimeout(() => {
      this.generators = this.policySummary as any;
      this.totalPremium = this.grandPremium as number;
    }, 1500);
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false) {
        const payload = {
          value: value,
          rate: event.target.accessKey,
        } as object;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            `${variables.CART_ADDITION_ROUTE}${this.policyId}/premium`,
            payload
          )
            .then(({ data }) => {
              //Assign data to form fields
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    onLoadPremium(premium, rate, id) {
      setTimeout(() => {
        // Check if the phone item premium value is a number
        if (isNaN(premium) == false) {
          const payload = {
            value: premium,
            rate: rate,
          } as object;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.premiumCalculationRoute}`, payload)
              .then(({ data }) => {
                // Calculate premium from vehicle value
                variables.premiumCalculator(1, data.data, id);
                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                console.log(error);
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        } else {
          return;
        }
      }, 3000);
    },
    addDynamicField() {
      this.generators.push({
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: "",
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.generators.splice(counter, 1);

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    imagesSelected(event, position) {
      this.generators[position].generatorPhoto = event.target.files;
    },
    receiptsSelected(event, position) {
      this.generators[position].generatorReceiptPhoto = event.target.files;
    },
  },
  computed: {
    mainRate() {
      return this.rate;
    },
  },
});
