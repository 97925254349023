
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";

export default defineComponent({
  name: "address-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    addressName: String,
    modelName: String,
    labelName: String,
  },
  data() {
    return {
      address: "" as string,
    };
  },
  created() {
    // Add Google autocomplete API script to heder
    // this.setMultipleAttributesOnElement();

    // Update the address hidden field on update
    if (this.addressName != "" || this.addressName != null) {
      setTimeout(() => {
        this.address = this.addressName as string;
      }, 1500);
    }
  },
  methods: {
    getAddressCoordinates() {
      const mainAddress = document.getElementById(
        "main-address"
      ) as HTMLInputElement;

      const addressField = document.querySelector(
        "#autocomplete"
      ) as HTMLInputElement;

      const autocomplete = new (window as any).google.maps.places.Autocomplete(
        document.getElementById("autocomplete")
      );
      autocomplete.setComponentRestrictions({
        // restrict the country
        country: ["ng"],
      });

      // Get the place details from the autocomplete object.
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        mainAddress.value = place.formatted_address;
      });

      setTimeout(() => {
        addressField.focus();
        this.address = mainAddress.value;
      }, 1500);

      // const place = autocomplete["result"];
      // console.log(autocomplete);
      // console.log(place.geometry.location.lat(), place.geometry.location.lng());
    },
    setMultipleAttributesOnElement() {
      const elem = document.createElement("script");

      const elementAttributes = {
        src:
          "https://maps.googleapis.com/maps/api/js?key=" +
          variables.VUE_APP_GEOLOCATION_API_KEY +
          "&libraries=places",
        class: "auto-complete-header",
        id: "auto-complete-header",
      };

      Object.keys(elementAttributes).forEach((attribute) => {
        elem.setAttribute(attribute, elementAttributes[attribute]);
      });

      // Check if the script already exists
      if (!variables.scriptContainsClassName("auto-complete-header")) {
        document.head.appendChild(elem);
      }
    },
    blurEventHandler: function(e) {
      e.preventDefault();

      const mainAddress = document.getElementById(
        "main-address"
      ) as HTMLInputElement;

      const addressField = document.querySelector(
        "#autocomplete"
      ) as HTMLInputElement;

      this.address = mainAddress.value;

      if (this.address.length < 15) {
        addressField.focus();
        this.address = mainAddress.value;
      }
    },
  },
  computed: {
    mainAddressName() {
      return this.addressName;
    },
  },
});
